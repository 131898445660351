import * as React from 'react'
import Layout from "../components/Layout"
import Seo from '../components/Seo'

import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import {BsStrava} from 'react-icons/bs'
import {FiInstagram} from 'react-icons/fi'
import {AiFillLinkedin} from 'react-icons/ai'

import { AnimationOnScroll } from 'react-animation-on-scroll';

const Cont = styled.section`

    padding: 5% 5%;
    h1{
        font-weight: 500;
        text-align: center;
        font-size: 2em;
        margin: 5% 0;

        line-height: 2em;
        strong{
            color: #34D277;
        }
    }
    div.intro{
        border-top: 5px double #34D277;
        border-bottom: 5px double #34D277;
        padding:5% 5%;
        max-width: 600px;
        margin: auto;
        p,h2{
        text-align: center;  
        padding-bottom: 10px;
    }
    }
    
`

const Persons = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: auto;
`

const Person = styled.section`
    display: flex;
    min-width: 300px;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
    h3{
        font-size: 1.2em;
    }
    div{
        padding: 10px 15px;
    }
    .img{
        border-radius: 50%;
        width: 100px;
        height: 100px;
        z-index: -1;

        ${({theme})=> theme.media.tablet}{
            width: 120px;
            height: 120px;

        }
    }
    a{
      text-decoration: none;
      color: black;
    }

    .pata{
        text-align: right;
    }
`


const Social = styled.div`
    display: flex;
    justify-content: space-around;
    
    a{
        font-size: 1.5em;
    }
`

const Contact = () => {
    return(
        <Layout>
            <Seo title="Kontak"/>
            <Cont>
                <div className='intro'>
                    <h1>O NAS</h1>
                    <p >Przygody rowerowe towarzyszą nam od dzieciństwa. Znamy smak wsi wschodniej Polski, jej uroków i wspaniałych krajobrazów. Ja swoje wakacje spędzałem na Roztoczu, we wsi Susiec. Patrycja mieszkała na Podlasiu, w Kozuchowie.<br/><br/>
                    Tym wyścigiem pragniemy wrócić do naszych korzeni. Ultra Race Roztocze to jest podróż do malowniczych zakątków wschodniej wsi. Do naszego a może i Waszego dzieciństwa. Do krainy pachnących sosen, szumiących buków, wielobarwnych pól i łąk. Do krainy, w której czas zwalnia.</p>
                </div>

                <h1>
                    Masz pytania? <br/><strong>Skontaktuj</strong> się z nami.
                </h1>
                <Persons>
                    <AnimationOnScroll animateIn="animate__fadeInLeft" duration={1} delay={0.4} animateOnce={true}>

                    <Person >
                        <StaticImage 
                            src="../assets/images/tomek.png"
                            alt="tomek oleksy"
                            placeholder="blurred"
                            layout='constrained'
                            className='img'
                            loading='lazy'
                            >
                        </StaticImage>
                        <div>
                            <h3>Tomek</h3>
                            <a href='mailto:tomek@moreultrarace.pl' title='tomek@moreultrarace.pl'><p>tomek@moreultrarace.pl</p></a>
                            <phone>+48 728-492-615</phone>
                            <Social>
                                <a href="https://www.strava.com/athletes/4007729" target={"_blank"} rel="noreferrer"><BsStrava/></a>
                                <a href="https://www.instagram.com/tomaszoleksy/" target={"_blank"} rel="noreferrer"><FiInstagram/></a>
                                <a href="https://www.linkedin.com/in/tomasz-oleksy-8494ba127/" target={"_blank"} rel="noreferrer"><AiFillLinkedin/></a>
                            </Social>
                        </div> 
                    </Person>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} delay={0.6} animateOnce={true}>

                    <Person>
                        <div className='pata'>
                            <h3>Patrycja</h3>
                            <a href='mailto:patrycja@moreultrarace.pl' title='patrycja@moreultrarace.pl'><p>patrycja@moreultrarace.pl</p></a>
                            <phone>+48 515-187-268</phone>
                            <Social>
                                <a href="https://www.strava.com/athletes/10372486" target={"_blank"} rel="noreferrer"><BsStrava/></a>
                                <a href="https://www.instagram.com/rucpata/" target={"_blank"} rel="noreferrer"><FiInstagram/></a>
                                <a href="https://www.linkedin.com/in/patrycja-rucińska-4b1959103/" target={"_blank"} rel="noreferrer"><AiFillLinkedin/></a>
                            </Social>
                            
                        </div> 
                        <StaticImage 
                            src="../assets/images/pata.png"
                            alt="patrycja rucińska"
                            placeholder="blurred"
                            layout='constrained'
                            className='img'
                            loading='lazy'
                            >
                        </StaticImage>
                    </Person>
                    </AnimationOnScroll>
                </Persons> 
            </Cont>
        </Layout>
    )
}

export default Contact
